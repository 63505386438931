import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attorneys',
  templateUrl: './attorneys.component.html',
  styleUrls: ['./attorneys.component.css']
})
export class AttorneysComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
