import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  showMenu = false;
  isHome: boolean;

  constructor(public location: Location) { }

  ngOnInit() {
    this.isHome = this.isPageActive('');
  }

  /**
   * Extracts and compare the name of current page
   * @param path String
   * @param page String
   */
  public isPageActive(page: string): boolean {
    const substr: string = this.location.path().substr(this.location.path().indexOf('/') + 1);
    let pageName = '';

    if (substr.indexOf('/') === -1) {
      pageName = substr;
    } else {
      pageName = this.location.path().substr(this.location.path().indexOf('/') + 1, substr.indexOf('/'));
    }

    return  pageName === page;
  }

  menu_button_clicked() {
    this.showMenu = !this.showMenu;
  }
}
