import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {interval} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  showMenu = false;
  @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;
  @ViewChild('commentModal', {static: false}) commentModal: SlickCarouselComponent;
  sliderCount: number;
  timer;

  constructor() {
    this.updateSliderCount(window.innerWidth);
  }

  ngOnInit() {
    this.headingAnimation();
    const secondsCounter = interval(1300);
    this.timer = secondsCounter.subscribe(n => {
      this.slickModal.slickNext();
      this.commentModal.slickNext();
    });
  }

  ngOnDestroy() {
    this.timer.unsubscribe();
  }

  slickInit(e) {
    console.log(e);
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

  // Animation function
  headingAnimation = () => {
    const TxtType = function(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
      const i = this.loopNum % this.toRotate.length;
      const fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      const that = this;
      let delta = 200 - Math.random() * 100;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(() => {
        that.tick();
      }, delta);
    };

    window.onload = () => {
      const elements = document.getElementsByClassName('typewrite');
      for (let i = 0; i < elements.length; i++) {
        const toRotate = elements[i].getAttribute('data-type');
        const period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      const css = document.createElement('style');
      css.type = 'text/css';
      css.innerHTML = '.typewrite > .wrap { border-right: 0.08em solid #fff}';
      document.body.appendChild(css);
    };
  };

  menu_button_clicked() {
    this.showMenu = !this.showMenu;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.updateSliderCount(window.innerWidth);
  }

  updateSliderCount(width: number) {
    if (width < 870) {
      this.sliderCount = 1;
    } else if (width < 1300) {
      this.sliderCount = 2;
    } else if (width < 1700) {
      this.sliderCount = 3;
    } else {
      this.sliderCount = 4;
    }
  }
}
