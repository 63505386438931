import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {} from 'googlemaps';
import {ApiService} from '../services/api.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  @ViewChild('map', {static: true}) mapElement: ElementRef;
  map: google.maps.Map;

  name = '';
  email = '';
  subject = '';
  message = '';
  response = '';
  isError = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    const position = new google.maps.LatLng(28.667206, 77.216444);
    const mapProperties = {
      center: position,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    const marker = new google.maps.Marker({map: this.map, position});
    marker.setMap(this.map);
  }

  sendMessage() {
    this.isError = true;
    this.response = '';

    const isEmailValid = new FormControl(this.email, [
      Validators.required,
      Validators.email
    ]).valid;

    if (this.name === '' || this.email === '' || this.subject === '' || this.message === '') {
      this.response = 'All Fields are required.';
      return;
    } else if (!isEmailValid) {
      this.response = 'Enter a valid Email Address.';
      return;
    }

    this.apiService.sendFeedback(this.name, this.email, this.subject, this.message).subscribe((data: any) => {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';

      this.isError = false;
      this.response = 'Message Sent.';
    }, (error) => {
      this.isError = true;
      this.response = error;
      console.log(error);
    });
  }
}
