import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  message: any = '';
  data: any = '';

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    switch (error.status) {
      case 400: {
        if (error.error.data) {
          this.message = [error.error.data.message];
        } else {
          for (const x in error.error) {
            if (error.error.hasOwnProperty(x)) {
              this.message = error.error[x][0];
            }
          }
        }
        // console.log('Bad Request');
        break;
      }
      case 403: {
        // console.log('It\'s a 403');
        this.message = ['You are not logged in or the token has expired. Please login again!'];
        break;
      }
      case 404: {
        // this.message = [error.error.data.message];
        this.message = 'Not Found';
        break;
      }
      case 422: {
        this.message = ['Improper data sent!'];
        for (const x in error.error.data) {
          if (error.error.data.hasOwnProperty(x)) {
            this.message = error.error.data[x][0];
          }
        }
        break;
      }
      case 429: {
        this.message = ['Too many request'];
        break;
      }
      case 500: {
        this.message = ['A server side error occurred. Please report this to ' + environment.supportEmail];
        break;
      }
      case 504: {
        this.message = ['Gateway timeout, Please check your internet connection!'];
        break;
      }
      case 0: {
        this.message = ['Please check your internet connection!'];
        break;
      }
      default: {
        this.message = [error.error];
        break;
      }
    }
    return throwError(this.message);
  }

  sendFeedback(name: string, email: string, subject: string, message: string) {
    this.data = {
      name,
      email,
      subject,
      message
    };
    return this.http.post(environment.apiUrl + 'feedback/', this.data).pipe(catchError(this.handleError));
  }
}
